
import Vue from 'vue';
import LottieIcon from './LottieIcon.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { ItemService } from '../services/item-service';
import { ValidationHelper } from '../helpers/ValidationHelper';
import { CookieHelpers } from '../helpers/CookieHelper';

import {
  Item,
  Material,
  Color,
  Die,
  ItemInfo,
  Lamination,
  Finish,
  ItemDetails,
  ItemState,
  Brand,
  GtinType,
  HumanReadable
} from '@/store/models/ItemModel';
import { AlertModel } from '@/store/models/DialogModel';
import { OrdersService } from '@/services/orders-service';
import AlertComponent from '@/components/AlertLayout.vue';
import { BuildItemAlertMessage } from '@/helpers/ObjectHelper';
import AuthService from '@/services/auth-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Confirm } from '@/helpers/AuthHelper';
import { Customer } from '@/store/models/CustomerModel';
import J1LFileUpload from '@/components/J1LFileUpload.vue';
import { VarDataDynamicTextTypes } from '@/ConstValues';
import { Country } from '@/store/models/AddressModel';
import { ManufacturingCountryTextTypes } from '@/store/models/TransparencyItemModel';
import { getExpectedCheckDigit, isValidCheckDigit } from '@/helpers/StringHelper';
import { CustomerService } from '@/services/customer-service';
import { SaveItemModel } from '@/store/models/RequestModels';

@Component({
  components: {
    AlertComponent,
    LottieIcon,
    J1LFileUpload
  }
})
export default class AddEditItem extends Vue {
  $refs!: {
    ItemForm: HTMLFormElement;
    alertForMaterial: HTMLFormElement;
    J1LFileUpload: HTMLFormElement;
    J1LSpotVarnishFileUpload: HTMLFormElement;
  };
  /* Properties */
  @Prop() editingItem!: ItemDetails;

  /* Store Actions */
  @Getter('areDiesLoading', { namespace: 'dies' })
  isDiesLoading!: any;
  @Getter('dieTemplates', { namespace: 'dies' })
  dieTemplates!: any;
  @Getter('getCustomer', { namespace: 'customer' })
  customer!: Customer;
  @State('itemInfo', { namespace: 'items' })
  itemInfo!: ItemInfo;

  /* Watchers */
  @Watch('defaultItem', { deep: true })
  oneditingItemChanged(val: ItemDetails, oldVal: ItemDetails) {
    if (
      oldVal.State === ItemState.New ||
      (oldVal.State === ItemState.Edit && (oldVal.Id || oldVal.PublicId))
    ) {
      this.defaultItem.HasChanged = true;
    }
  }
  @Watch('defaultItem.PartNumber')
  async onDefaultItemChanged(val: any) {
    this.isDuplicatePartNumber = false;
    const response = await ItemService.IsDuplicatePartNumber(val);
    this.isDuplicatePartNumber = response.data.DupsFound;
  }
  @Watch('newPartNumber')
  onNewPartNumberChanged() {
    if (this.defaultItem.PartNumber && this.newPartNumber != this.defaultItem.PartNumber)
      this.checkForDuplicates();
  }

  /* Data */
  gtinTypes: GtinType[] = [
    { Name: 'UPC', Id: 0, CharLength: 12 },
    { Name: 'EAN-8', Id: 1, CharLength: 8 },
    { Name: 'EAN-13', Id: 2, CharLength: 13 },
    { Name: 'EAN-14', Id: 3, CharLength: 14 }
  ];
  humanReadables: HumanReadable[] = [
    { Id: VarDataDynamicTextTypes.None, Type: 'None' },
    { Id: VarDataDynamicTextTypes.PublicCode, Type: 'Public Code' },
    { Id: VarDataDynamicTextTypes.UPC, Type: 'UPC' },
    { Id: VarDataDynamicTextTypes.ASIN, Type: 'Asin' },
    { Id: VarDataDynamicTextTypes.Ean13, Type: 'Ean13' },
    { Id: VarDataDynamicTextTypes.FNSKU, Type: 'FNSKU' }
  ];
  states: any[] = [];
  countries: Country[] = [];
  brands: Brand[] = [];
  title: string = 'Add';
  alertOption!: AlertModel;
  hasError: boolean = false;
  shouldShowDialog: boolean = false;
  newPartNumber: string = '';
  changePartNumberText: string = '';
  valid: boolean | null = false;
  partNumberLoading = false;
  on: boolean = false;
  shouldShowAdditionalOptions: boolean = false;
  shouldOverridePartNumber: boolean = false;
  defaultItem: ItemDetails = new ItemDetails({
    Finish: new Finish({
      Id: 2,
      BadgeText: 'Gloss Finish'
    }),
    Material: new Material({
      Id: 2,
      BadgeText: 'White Paper'
    }),
    Color: new Color({
      Id: 4,
      ClickColors: '4 (CMYK)'
    })
  });
  submitText: string = 'CREATE ITEM';
  loading: boolean = false;
  isSavingItem: boolean = false;
  isDuplicatePartNumber = false;
  isChangingPartNumber = false;
  shouldForceEnable: boolean = false;
  isCheckingForDuplicates: boolean = false;
  shouldShowDuplicateAlert: boolean = false;
  shouldShowPartNumberChangeAlert: boolean = false;
  previousDie: any = new Die();
  customerOrders: any = [];
  confirmationText: string = '';
  isMaterialAlertActive: boolean = false;
  isOptoutOptionChecked: boolean = false;
  availableColors: any = [];
  isAdmin: boolean = false;
  dropzoneElement: HTMLElement | null = null;
  shouldUseExistingFile: boolean = false;
  manufacturingCountryTextTypes: ManufacturingCountryTextTypes[] = [
    { id: 0, text: 'Manufactured in' },
    { id: 1, text: 'Made in' },
    { id: 2, text: 'Assembled in' }
  ];
  /* Utility Functions */
  async checkForExistingOrders() {
    if (this.editingItem) {
      const response = await OrdersService.GetCustomerOrdersByItemId(
        this.defaultItem.PublicId
      );
      this.customerOrders = response.data;
      if (this.customerOrders) {
        let confirmationText =
          'The changes you are about to perform may affect the price(s) of the following orders:';
        this.customerOrders.forEach((co: any) => (confirmationText += ` ${co.PO} `));
        Confirm(
          () => {
            this.onClickItem();
          },
          'Customer Orders affected by change',
          confirmationText,
          'Cancel',
          'Continue'
        );
        return;
      }
    }
    this.onClickItem();
  }
  onClickItem(didAccept: boolean = true) {
    if (!didAccept) return;
    let model = new SaveItemModel(this.defaultItem);
    model.TempFileName = this.$refs.J1LFileUpload._tempFileName();
    model.OriginalFileName = this.$refs.J1LFileUpload._originalFileName()
      ? this.$refs.J1LFileUpload._originalFileName()
      : '';
    model.TempSpotVarnishFileName = this.$refs.J1LSpotVarnishFileUpload?._tempFileName();
    model.OriginalSpotVarnishFileName = this.$refs.J1LSpotVarnishFileUpload?._originalFileName();
    model.ShouldOverride = this.shouldOverridePartNumber;
    this.valid = this.$refs.ItemForm.validate();
    if (this.valid) {
      this.saveItem(model);
      this.clearData();
    } else {
      SetSnackBar('Please enter in all the required inputs');
    }
  }
  dropzoneTest(e: any) {
    this.$refs.J1LFileUpload.setFileData(e.dataTransfer.files[0]);
  }
  onEditingFileDieReset() {
    let dieId = this.defaultItem.Die.Id;
    if (dieId) {
      this.previousDie = this.dieTemplates.find((d: Die) => d.Id === dieId);
      this.defaultItem.Die = new Die();
    } else {
      this.previousDie = new Die();
    }
    return;
  }
  onFileUploaded(file: File) {
    if (this.$refs.J1LFileUpload._tempFileName()) this.onEditingFileDieReset();
    if (!this.defaultItem.Description) {
      this.defaultItem.Description = ValidationHelper.removeFileExtension(file.name);
    }
    if (this.editingItem) {
      this.defaultItem.HasChanged = true;
      if (
        this.$refs.J1LFileUpload._tempFileName() &&
        (!this.defaultItem.HasVarnish ||
          this.$refs.J1LSpotVarnishFileUpload._tempFileName())
      ) {
        return;
      }
      let fileType = this.$refs.J1LFileUpload._tempFileName()
        ? 'Item File'
        : 'Varnish File';
      let otherFileType = fileType === 'Item File' ? 'Varnish File' : 'Item File';
      Confirm(
        () => {
          this.shouldUseExistingFile = true;
        },
        `New ${fileType} Detected`,
        `A new <strong>${fileType}</strong> was detected. This will create a new version of your item. Would you like to use the existing <strong>${otherFileType}</strong> for this item?`,
        'No',
        'Yes',
        true
      );
    }
  }
  enablePartNumberEdit() {
    if (!this.shouldForceEnable) {
      this.changePartNumberText = 'Confirm';
      this.shouldForceEnable = true;
      this.shouldShowPartNumberChangeAlert = true;
    }
  }
  disablePartNumberEdit() {
    this.changePartNumberText = 'Change';
    this.shouldForceEnable = false;
    this.shouldShowPartNumberChangeAlert = false;
    this.shouldShowDuplicateAlert = false;
    if (this.editingItem) this.newPartNumber = this.editingItem.PartNumber;
  }
  async ChangePartNumber(shouldOverride: boolean = false) {
    if (this.newPartNumber === this.editingItem.PartNumber) {
      SetSnackBar('The part number entered needs to be different than the previous');
      return;
    }
    if (shouldOverride) this.shouldShowDuplicateAlert = false;
    this.shouldOverridePartNumber = shouldOverride;
    this.editingItem.PartNumber = this.newPartNumber;
    this.defaultItem.PartNumber = this.newPartNumber;
    this.disablePartNumberEdit();
  }
  async checkForDuplicates() {
    this.isCheckingForDuplicates = true;
    setTimeout(async () => {
      if (
        this.newPartNumber &&
        this.defaultItem &&
        this.newPartNumber.toLowerCase() !== this.defaultItem.PartNumber.toLowerCase()
      ) {
        const response = await ItemService.IsDuplicatePartNumber(this.newPartNumber);
        if (response.data && response.data.DupsFound)
          this.shouldShowDuplicateAlert = true;
        else {
          this.shouldShowDuplicateAlert = false;
        }
        this.isCheckingForDuplicates = false;
      }
      this.isCheckingForDuplicates = false;
    }, 100);
  }

  showAdditionalFields() {
    this.shouldShowAdditionalOptions = !this.shouldShowAdditionalOptions;
    this.$nextTick(() => {
      const divEl = document.querySelector('.v-card__text.form-container');
      if (divEl) divEl.scrollTop = divEl.scrollHeight;
    });
  }

  setToPreviousDie() {
    this.defaultItem.Die = new Die(this.previousDie);
  }
  handleResponse(
    didAccept: boolean,
    itemId: string = '',
    didChangePartNumber: boolean = false
  ) {
    if (!didAccept) {
      this.$refs.ItemForm.reset();
      this.clearData();
    }
    let expectedVersion = this.editingItem ? this.editingItem.Version + 1 : 1;
    this.$emit('response', { didAccept, itemId, expectedVersion, didChangePartNumber });
  }
  clearData() {
    this.$refs.J1LFileUpload.clearData();
  }
  refreshItem() {
    this.defaultItem = new ItemDetails({
      Finish: new Finish({
        Id: 2,
        BadgeText: 'Gloss FInish'
      }),
      Material: new Material({
        Id: 2,
        BadgeText: 'White Paper'
      }),
      Color: new Color({
        Id: 4,
        ClickColors: '4 (CMYK)'
      })
    });
  }
  filterObject(item: any, queryText: string, itemText: string) {
    return (
      item.Tag.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase()) ||
      item.Size.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }
  setEditItemInfo() {
    this.title = 'Update';
    this.availableColors = this.itemInfo.Colors;
    this.defaultItem = new ItemDetails(this.editingItem);
    if (this.defaultItem.ManufacturingCountry.CountryValue)
      this.defaultItem.ManufacturingCountry = this.countries.find(
        c => c.CountryValue == this.defaultItem.ManufacturingCountry.CountryValue
      ) as Country;
    this.defaultItem.GtinType = this.gtinTypes.find(
      g => g.Id == this.defaultItem.GtinType?.Id
    ) as GtinType;
    console.log(this.defaultItem.GtinType);
    this.shouldShowDialog = true;
  }
  goToExistingPartNumber() {
    this.$emit('searchItem', this.defaultItem.PartNumber);
    this.closeDialog();
  }

  async autoGeneratePartNumber() {
    this.partNumberLoading = true;
    const response = await ItemService.GetNewPartNumber('default');
    this.defaultItem.PartNumber = response.data;
    this.partNumberLoading = false;
  }
  async saveItem(model: any) {
    this.loading = true;

    try {
      this.isSavingItem = true;
      if (model.TempFileName || model.TempSpotVarnishFileName) {
        this.onDieChange();
      }
      const response = await ItemService.SaveItem(model);
      if (response.data) {
        this.handleResponse(true, response.data.result, this.shouldOverridePartNumber);
        this.loading = false;
      }
    } catch (err) {
      this.loading = false;
      SetSnackBar('Something went wrong updating your item');
    }
    this.isSavingItem = false;
  }
  async openDialog() {
    this.shouldShowDialog = true;
    this.loadDefaultData();
    const response = await AuthService.isAdmin();
    this.isAdmin = response.data;
    if (this.editingItem) {
      this.changePartNumberText = 'Change';
      this.newPartNumber = this.editingItem.PartNumber;
      this.setEditItemInfo();
      this.shouldShowAdditionalOptions =
        this.editingItem.HasVarnish ||
        this.editingItem.HasBarcode ||
        this.editingItem.ShouldEnablePantonesForProofs;
      return;
    } else {
      this.defaultItem.State = ItemState.New;
      this.defaultItem.IsNew = true;
      this.defaultItem.GtinType = this.gtinTypes[0];
      this.defaultItem.HumanReadable = this.humanReadables[0];
    }
    this.availableColors = this.itemInfo.Colors;
  }
  onDieChange() {
    this.$emit('setShouldItemRefreshToTrue', true);
  }
  closeDialog() {
    this.$refs.ItemForm.reset();
    this.clearData();
    this.refreshItem();
    this.disablePartNumberEdit();
    this.shouldShowDialog = false;
  }
  async onMaterialAlertClick(response: any) {
    this.isMaterialAlertActive = false;
    if (this.isOptoutOptionChecked) {
      CookieHelpers.setOptoutOfAlertCookie();
    }
  }
  checkForMetallicOrClearFinish() {
    let cookie = CookieHelpers.getCookie('OptoutOfWarning');
    let isUserOptedOutFromMaterialWarning = cookie == 'true';
    let isMaterialAlertActive =
      (this.defaultItem.Material.Id === 5 || this.defaultItem.Material.Id === 7) &&
      !isUserOptedOutFromMaterialWarning;
    if (this.defaultItem.Material.Id === 7) {
      this.resetColors();
    } else if (this.defaultItem.Material.Id === 5) {
      this.addColor(5);
      this.removeColor(6);
      this.removeColor(7);
    } else {
      this.removeColor(5);
      this.removeColor(6);
      this.removeColor(7);
    }
    if (this.defaultItem.Material.Id === 5) {
      this.defaultItem.Color = new Color({
        Id: 5,
        ClickColors: '5 (CMYK + White)'
      });
    } else if (this.defaultItem.Material.Id === 7) {
      this.defaultItem.Color = new Color({
        Id: 6,
        ClickColors: 'FULL COLOR + 2 HIT WHITE'
      });
    } else {
      if (this.defaultItem.Color.Id > 4) {
        this.defaultItem.Color = new Color({
          Id: 4,
          ClickColors: 'CMYK'
        });
      }
    }
    if (isMaterialAlertActive) {
      this.alertOption = BuildItemAlertMessage('material', this.defaultItem, null);
      this.isMaterialAlertActive = true;
    }
    if (this.defaultItem.Material.Id === 18) this.setLamination(4);
  }
  setLamination(id: number) {
    const finish = this.itemInfo.Lamination.find(l => l.Id === id);
    this.defaultItem.Finish = finish;
  }
  removeColor(id: number) {
    this.availableColors = this.availableColors.filter((c: Color) => c.Id !== id);
  }
  addColor(id: number) {
    this.availableColors.push(this.itemInfo.Colors.find((c: Color) => c.Id == id));
    this.availableColors = this.availableColors.sort((c: any) => c.Id);
  }
  resetColors() {
    this.availableColors = this.itemInfo.Colors;
  }

  isDataMatrixInformationValid() {
    let isGtinValid = true;
    for (let func of this.gtinRules) {
      if (func(this.defaultItem.Gtin) !== true) isGtinValid = false;
    }
    return !this.defaultItem.HasBarcode || isGtinValid;
  }

  canSaveItem() {
    if (!this.$refs.J1LFileUpload) return false;
    let doesNeedAndHaveFile = this.editingItem
      ? true
      : !!this.$refs.J1LFileUpload._originalFileName();
    let doesNeedAndHaveSpotVarnishFile = this.defaultItem.HasVarnish
      ? this.editingItem?.HasVarnish ||
        !!this.$refs.J1LSpotVarnishFileUpload._originalFileName()
      : true;
    let hasFiles = true;
    if (this.editingItem && !!this.$refs.J1LSpotVarnishFileUpload?._originalFileName()) {
      hasFiles =
        this.shouldUseExistingFile || !!this.$refs.J1LFileUpload?._originalFileName();
    }
    if (
      this.editingItem &&
      this.$refs.J1LFileUpload?._originalFileName() &&
      this.defaultItem.HasVarnish
    ) {
      hasFiles =
        this.shouldUseExistingFile ||
        !!this.$refs.J1LSpotVarnishFileUpload?._originalFileName();
    }
    hasFiles = hasFiles && doesNeedAndHaveFile && doesNeedAndHaveSpotVarnishFile;

    let canSaveItem =
      this.defaultItem.PartNumber &&
      this.defaultItem.PartNumber.length > 0 &&
      this.defaultItem.Description &&
      this.defaultItem.Description.length > 0 &&
      this.defaultItem.Die &&
      this.defaultItem.Die.Id > 0 &&
      this.$refs.J1LFileUpload.canSaveItem() &&
      hasFiles &&
      !this.shouldShowDuplicateAlert &&
      this.isDataMatrixInformationValid() &&
      this.defaultItem.HasChanged;
    return canSaveItem;
  }

  /* Loaders */
  async loadDefaultData() {
    const promises = [this.getCountries(), this.getBrands(), this.getStates()];
    await Promise.all(promises);
  }
  async getCountries() {
    const { data: countries } = await CustomerService.GetCountries();
    this.countries = countries;
    if (this.editingItem) {
      this.defaultItem.ManufacturingCountry = this.countries.find(
        c => c.CountryValue == this.defaultItem.ManufacturingCountry.CountryValue
      ) as Country;
    } else {
      this.defaultItem.ManufacturingCountry = this.countries[0];
    }
  }
  async getBrands() {
    const { data } = await CustomerService.GetCurrentCustomer();
    this.brands = data.Brands.map((b: any) => new Brand(b));
    if (this.editingItem) {
      this.defaultItem.Brand = this.brands.find(
        b => b.Id == this.defaultItem.Brand.Id
      ) as Brand;
    } else {
      this.defaultItem.Brand = this.brands[0];
    }
  }
  async getStates() {
    const { data } = await CustomerService.GetStates();
    this.states = Object.keys(data).map(key => ({
      id: key,
      name: data[key]
    }));
  }
  /* Computed */
  get gtinRules() {
    return [
      (v: string) => !!v || `${this.defaultItem.GtinType.Name} is required`,
      (v: string) =>
        (!!v && v.length === this.defaultItem.GtinType.CharLength) ||
        `${this.defaultItem.GtinType.Name} must be ${this.defaultItem.GtinType.CharLength} characters`,
      (v: string) => v !== '123456789014' || 'A valid GTIN is required.',
      (v: string) =>
        (!!v &&
          v.length === this.defaultItem.GtinType.CharLength &&
          isValidCheckDigit(v)) ||
        `GTIN is not valid expected check digit: ${getExpectedCheckDigit(v)}`
    ];
  }

  /* Mounted */
  async mounted() {}
  /* Created */
}
